import React from "react"

import "../css/pages/renovation.scss"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import {Link} from "gatsby";
import Contact from "../containers/index/contact";

const Renovation = () => (
  <Layout>
    <SEO title="Travaux de rénovation à Laon dans l'Aisne (02)"
         description={"L'entreprise Athies Batiment basée à Laon intervient pour les travaux de rénovation dans l'Aisne et dans la Marne. Faites appel à nos artisans  qualifiés."}/>
    <div className={"page renovation"}>
        <div className={"header"}>
            <h1 className={"title"}>Travaux de rénovation à Laon</h1>
        </div>

        <Section>
            <h2>Pourquoi faire appel à nos artisans ?</h2>
            <p>
                Pour vos <strong>travaux de rénovation, extension et aménagement de maison</strong>, nos artisans réaliseront vos projets avec profesionnalisme. Nous réalisons aussi la rénovation de la <strong>façade</strong> de votre habitation de façon complète et selon vos besoins.
            </p>

            <p>
                Nos artisans, <strong>qualifiés et expérimentés</strong>, réaliseront vos travaux de rénovation selon vos besoins. Nous intervenons principalement dans <strong>l’Aisne (02) et dans la Marne (51)</strong>, dans le secteur proche de Laon, mais nous pouvons aussi nous déplacer dans d'autres secteurs. Pour cela, contactez-nous : <Link to={"/#contact"}>cliquez-ici</Link>.
            </p>
        </Section>

        <Contact/>
    </div>
  </Layout>
)

export default Renovation
